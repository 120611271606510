import Vue from 'vue'
import Vuex from 'vuex'

import firebaseApp from '@/firebase/init'
// import router from '@/router/index'

Vue.use(Vuex)

const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
const getFaciData = firebaseApp.functions('asia-northeast1').httpsCallable('getFaciData')

export default new Vuex.Store({
  state: {
    /* Location */
    userLocation: null,
    userLocationExpiryTimestamp: null,
    userAllowsLocationDetection: null,

    /* User */
    user: null,
    userName: null,
    userEmail: null,
    userAvatar: null,

    /* Profile */
    profile: null,

    /* Organisation */
    organisation: null,
    baseUrl: 'https://pyts.link',
    adminBaseUrl: 'https://admin.playtours.app',

    /* Adventures */
    adventures: null,

    /* Facilitator */
    faciData: null,
    shownFaciTab: null,
    shownActionTab: null,

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,

    /* PlayTours Edit Page */
    recentLatLonRad: null,
    savedPlayTours: true,
    assistantJsonObj: null,

    /* ContactSolutionsTeam */
    hideContactSolutionsTeam: false,

    /* App Version */
    appVersion: null
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* Firebase User */
    user (state, payload) {
      if (payload.user) {
        state.user = payload.user
      }
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
    },
    removeUser (state, payload) {
      state.user = null
      state.userName = null
      state.userEmail = null
      state.userAvatar = null
    },

    /* Profile */
    profile (state, payload) {
      state.profile = payload
      state.userName = payload ? payload.name : null
    },

    /* Organisation */
    organisation (state, payload) {
      state.organisation = payload
      if (payload && payload.isWhiteLabelled) {
        state.baseUrl = 'https://pyts.link'
      }
    },

    /* Adventures */
    adventures (state, payload) {
      state.adventures = payload
    },

    /* In Faci Page */
    faciData (state, payload) {
      localStorage.playToursAdminFaciData = JSON.stringify(payload)
      state.faciData = payload
    },

    shownFaciTab (state, payload) {
      localStorage.setItem('playToursShownFaciTab', payload)
      state.shownFaciTab = payload
    },

    shownActionTab (state, payload) {
      state.shownActionTab = payload
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    },

    /* PlayTours Edit Page */
    recentLatLonRad: (state, payload) => {
      state.recentLatLonRad = payload
    },
    savedPlayTours: (state, payload) => {
      state.savedPlayTours = payload
    },
    assistantJsonObj: (state, payload) => {
      state.assistantJsonObj = payload
    },

    /* App Version */
    appVersion: (state, payload) => {
      state.appVersion = payload
    },

    getUserLocationMutation: (state, payload) => {
      state.userAllowsLocationDetection = payload.userAllowsLocationDetection
      if (payload.lat && payload.lon) {
        state.userLocation = {
          lat: payload.lat,
          lon: payload.lon
        }
      }
      const now = new Date()
      state.userLocationExpiryTimestamp = new Date(now.getTime() + 1 * 60000) // 1 minute from now
    }
  },
  actions: {
    getFaciData: (context, payload) => {
      getFaciData({
        facilitatorKey: payload
      }).then(res => {
        context.commit('faciData', res.data)
      }).catch(() => {
        context.commit('faciData', null)
      })
    },
    sendVerificationEmail: (context, payload) => {
      context.state.user.sendEmailVerification()
        .then(() => {
          masterFunctionAdmin({
            methodName: 'notify-email-verification-sent'
          })
        })
    },
    sendEmailVerifiedNotification: (context, payload) => {
      masterFunctionAdmin({
        methodName: 'notify-email-verified'
      })
    },
    getUserLocationAction: (context) => {
      if (!context.state.userLocation && context.state.userAllowsLocationDetection === false) {
        // router.push({ name: 'SelectLocation' })
        alert('Unable to get location as location access is blocked, use search function instead.')
      } else {
        navigator.geolocation.getCurrentPosition(pos => {
          context.commit('getUserLocationMutation', {
            userAllowsLocationDetection: true,
            lat: pos.coords.latitude,
            lon: pos.coords.longitude
          })
        }, () => {
          context.commit('getUserLocationMutation', {
            userAllowsLocationDetection: false,
            lat: null,
            lon: null
          })
          // router.push({ name: 'SelectLocation' })
          alert('Unable to get location as location access is blocked, use search function instead.')
        })
      }
    },
    gameBuilderActivityAction: (context, payload) => {
      console.log('hello')
      masterFunctionAdmin({
        methodName: 'register-game-builder-activity',
        adventureId: payload.adventureId,
        isEnter: !!payload.isEnter
      })
    }
  }
})
