const onlyNum = {
  install (Vue, options = null) {
    const directiveName = options && typeof options === 'object' && 'name' in options
      ? options.name : 'num'

    Vue.directive(directiveName, {
      inserted (el) {
        el.oninput = (event) => {
          let formattedValue
          if (!(event.target.value.includes('.') || event.target.value === '')) {
            formattedValue = Number(event.target.value)
          } else {
            formattedValue = event.target.value
          }
          el.value = isNaN(formattedValue) ? '' : formattedValue
        }
      }
    })
  }
}

export default onlyNum
