<template>
  <section v-if="show" class="section is-main-section pb-1">
    <article class="message is-warning">
      <div class="message-header">
        <p><i class="fas fa-exclamation-triangle mr-1"></i> Software Update</p>
      </div>
      <div class="message-body">
        There is a software update - please <i class="fas fa-redo mx-1"/> refresh this page to get the latest updates, bug fixes and optimisations. In some cases, you may need to click on any, different page and refresh.
      </div>
    </article>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import firebaseApp from '@/firebase/init'

export default {
  name: 'AppVersionWarning',
  data () {
    return {
      latestAppVersion: null
    }
  },
  computed: {
    show () {
      if (
        this.latestAppVersion &&
        this.appVersion &&
        parseInt(this.latestAppVersion.replaceAll('.', '')) > parseInt(this.appVersion.replaceAll('.', ''))
      ) {
        return true
      }
      return false
    },
    ...mapState(['appVersion'])
  },
  methods: {
    getLatestAppVersion () {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'get-latest-app-version'
      }).then(result => {
        this.latestAppVersion = result.data.version
        if (
          parseInt(this.latestAppVersion.replaceAll('.', '')) > parseInt(this.appVersion.replaceAll('.', ''))
        ) this.forceRefresh()
      }).catch(err => {
        console.log(err)
      })
    },
    forceRefresh () {
      var url = window.location.href
      const appender = 'upd=' + (new Date()).getTime()
      if (url.indexOf('?') > -1) {
        url += '&' + appender
      } else {
        url += '?' + appender
      }
      window.location.href = url
    }
  },
  mounted () {
    this.getLatestAppVersion()
  }
}
</script>
