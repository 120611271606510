<template>
  <div id="app">
    <div v-if="!inExhibitPage && !inFaciPage && user && profile && adventures && organisation && admins">
      <nav-bar />
      <aside-menu :menu="menu" />
      <transition-page>
        <router-view />
      </transition-page>
    </div>
    <div v-else-if="inFaciPage">
      <nav-bar />
      <aside-menu :menu="faciMenu" />
      <transition-page>
        <router-view />
      </transition-page>
    </div>
    <div v-else-if="inCertValidatorPage">
      <nav-bar />
      <aside-menu :menu="validatorMenu" />
      <transition-page>
        <router-view />
      </transition-page>
    </div>
    <div v-else-if="inExhibitPage">
      <!-- <nav-bar /> -->
      <!-- <aside-menu :menu="validatorMenu" /> -->
      <router-view />
    </div>
    <router-view v-else-if="!user" />
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
// import Login from '@/views/Login'
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import TransitionPage from './components/TransitionPage.vue'

export default {
  name: 'Home',
  components: {
    TransitionPage,
    AsideMenu,
    NavBar
  },
  data () {
    return {
      profile: null,
      adventures: null,
      organisation: null,
      admins: null
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    inFaciPage () {
      return this.$route.fullPath.includes('/faci')
    },
    inCertValidatorPage () {
      return this.$route.name === 'certificateValidator'
    },
    inExhibitPage () {
      return this.$route.fullPath.includes('/exhibit/')
    },
    menu () {
      const menuList = [
        [
          {
            to: '/',
            label: 'Dashboard',
            icon: 'fa-chart-line'
          },
          {
            to: '/playtours',
            label: 'Games',
            icon: 'fa-running'
            // updateMark: true
          },
          {
            label: 'Files',
            icon: 'fa-folder-open',
            menu: [
              {
                to: '/files/view',
                label: 'View Files',
                icon: 'fa-file-image'
              },
              {
                to: '/files/add',
                label: 'Add Files',
                icon: 'fa-upload'
              }
            ]
          },
          {
            label: 'Admins',
            icon: 'fa-lock',
            menu: [
              {
                to: '/admins/view',
                label: 'View Admins',
                icon: 'fa-users-cog'
              },
              {
                to: '/admins/add',
                label: 'Add Admin',
                icon: 'fa-user-plus'
              }
            ]
          },
          {
            to: '/profile',
            label: 'Profile',
            icon: 'fa-user-circle'
          },
          {
            to: '/organisation',
            label: 'Organisation',
            icon: 'fa-building'
          }
        ]
      ]
      if (!this.profile.isRootAccount) {
        return menuList
      } else {
        menuList[0].push({
          to: '/subscription',
          label: 'Subscription',
          icon: 'fa-bolt'
        })
        return menuList
      }
    },
    faciMenu () {
      return [
        [
          {
            label: 'Facilitator',
            icon: 'fa-chart-line'
          }
        ]
      ]
    },
    validatorMenu () {
      return [
        [
          {
            label: 'Validate Completion Certificates'
          }
        ]
      ]
    }
  },
  watch: {
    user: {
      immediate: true,
      handler (user) {
        const profiles = firebaseApp.firestore().collection('profiles')
        if (user) {
          this.$bind('profile', profiles.doc(user.uid))
        }
      }
    },
    profile: {
      immediate: true,
      handler (profile) {
        const profiles = firebaseApp.firestore().collection('profiles')
        const adventures = firebaseApp.firestore().collection('adventures')
        const organisations = firebaseApp.firestore().collection('organisations')
        this.$store.commit('profile', profile)
        if (profile) {
          this.$bind('adventures', adventures.where('organisationId', '==', profile.organisationId))
          this.$bind('organisation', organisations.doc(profile.organisationId))
          this.$bind(
            'admins',
            profiles.where('organisationId', '==', profile.organisationId)
          )
        }
        if (profile && !profile.verificationEmailSent) {
          this.$store.dispatch('sendVerificationEmail')
        }
        if (this.user && this.user.emailVerified && this.profile && !profile.emailVerified) {
          this.$store.dispatch('sendEmailVerifiedNotification')
        }
      }
    },
    organisation: {
      immediate: true,
      handler (organisation) {
        this.$store.commit('organisation', organisation)
      }
    },
    adventures: {
      immediate: true,
      handler (adventures) {
        this.$store.commit('adventures', adventures)
      }
    }
  }
}
</script>
