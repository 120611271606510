<template>
  <div v-if="shouldShowRating && !hasRatedLowerThan7 && !hasExpired && !isClosed">
    <section class="section is-main-section pb-0">
      <div v-if="hasRated">
        <article class="message">
          <div class="message-header">
            <p>We need your help!</p>
            <button @click="close()" class="delete" aria-label="delete"></button>
          </div>
          <div class="message-body">
            Could we kindly get you to do a G2 review for us? G2 is a review website for software and it will really help us a lot to spread the word about PlayTours and keep us running sustainably.
            <br><br>
            Here is the link to submit your review: <a href="https://www.g2.com/products/playtours/reviews" target="_blank" @click="sendRatingData(null, true, false)">https://www.g2.com/products/playtours/reviews</a>. The process is swift and straightforward and should take just a few minutes. Thank you so much.
            <br><br>
            - Mo, founder of PlayTours
          </div>
        </article>
      </div>
      <div v-else>
        <card-component title="Please rate your experience with PlayTours" icon="fa-poll">
          <div class="level mb-4">
            <div class="level-item has-text-centered" v-for="n in 10" :key="n">
              <div class="box is-clickable" style="box-shadow: none; border: 1px solid #ccc;" @click="showReviewMessage(n)">
                {{ n }}
              </div>
            </div>
          </div>
          <div class="is-size-7 has-text-grey-light has-text-centered">
            The higher the better
          </div>
        </card-component>
      </div>
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import firebaseApp from '@/firebase/init'

export default {
  name: 'G2Requester',
  components: {
    CardComponent
  },
  data () {
    return {
      hasRated: false,
      hasRatedLowerThan7: false,
      ratingDate: null,
      isClosed: false
    }
  },
  computed: {
    organisation () {
      return this.$store.state.organisation
    },
    shouldShowRating () {
      return this.organisation?.hasChargebeeAccount &&
             this.organisation.highestNoOfConcurrentUsers >= 5
    },
    hasExpired () {
      if (!this.ratingDate) return false
      const currentDate = new Date()
      const ratingDate = new Date(this.ratingDate)
      return (currentDate - ratingDate) > 14 * 24 * 60 * 60 * 1000 // 14 days in milliseconds
    }
  },
  methods: {
    showReviewMessage (rating) {
      this.ratingDate = new Date().toISOString()
      localStorage.setItem('playToursRatingRequesterRatingDate', this.ratingDate)
      if (rating >= 7) {
        this.hasRated = true
        localStorage.setItem('playToursRatingRequesterHasRated', rating)
      } else {
        this.hasRatedLowerThan7 = true
      }
      this.sendRatingData(rating, false, false)
    },
    close () {
      this.isClosed = true
      localStorage.setItem('playToursRatingRequesterIsClosed', 'true')
      this.sendRatingData(null, false, true)
    },
    sendRatingData (rating, isClickedG2, isClosed) {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'register-rating',
        orgId: this.organisation.id,
        isClickedG2: isClickedG2,
        isClosed: isClosed,
        rating: rating
      })
    }
  },
  mounted () {
    const rating = localStorage.getItem('playToursRatingRequesterHasRated')
    const ratingDate = localStorage.getItem('playToursRatingRequesterRatingDate')
    const isClosed = localStorage.getItem('playToursRatingRequesterIsClosed') === 'true'
    if (rating) {
      this.hasRated = rating >= 7
      this.hasRatedLowerThan7 = rating < 7
      this.ratingDate = ratingDate
    }
    this.isClosed = isClosed
  }
}
</script>
