<template>
  <card-component
    class="has-table has-mobile-sort-spaced"
    title="Articles & Guides"
    icon="fa-book"
  >
    <div class="px-5 py-5">
      <div class="mb-3">
        We have written detailed articles and guides to help you get started.
      </div>
      <div class="mb-2">
        <div
          @click="openPage('https://www.playtours.app/post/the-complete-guide-to-building-the-best-outdoor-scavenger-hunt-with-playtours')"
          class="is-clickable has-text-info"
        >
          <i class="fas fa-external-link-alt mr-1"></i>
          The Complete guide to building the best outdoor scavenger hunt with PlayTours
        </div>
      </div>
      <div class="mb-2">
        <div
          @click="openPage('https://www.playtours.app/post/top-mistakes-virtual-escape-room-creators-make-how-to-avoid-them-with-playtours')"
          class="is-clickable has-text-info"
        >
          <i class="fas fa-external-link-alt mr-1"></i>
          Top Mistakes Virtual Escape Room Creators Make (& How To Avoid Them With PlayTours)
        </div>
      </div>
      <div class="mb-2">
        <div
          @click="openPage('https://www.playtours.app/post/guide-to-using-playtours-answer-validation-system')"
          class="is-clickable has-text-info"
        >
          <i class="fas fa-external-link-alt mr-1"></i>
          <b> Allow facis to approve and reject tasks without pre-defined answers?</b> Guide To Using PlayTours’ Answer Validation System
        </div>
      </div>
      <div class="mb-2">
        <div
          @click="openPage('https://bit.ly/3pNAQba')"
          class="is-clickable has-text-info"
        >
          <i class="fas fa-external-link-alt mr-1"></i>
          <b> Allow players to "move" in 3D virtual reality?</b> Guide To Creating Your Very Own Virtual Space in PlayTours
        </div>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'

export default {
  name: 'Articles',
  components: {
    CardComponent
  },
  methods: {
    openPage (url) {
      window.open(url)
    }
  }
}
</script>

<style>

</style>
