<template>
  <div>
    <card-component
      v-if="!hideContactSolutionsTeam"
      class="has-table has-mobile-sort-spaced"
      title="Need help?"
      icon="fa-headset"
    >
      <div class="px-5 py-5">
        <div class="mb-3">
          Our Solutions Team provides a free 30 minutes, 1-on-1 consultation for all new accounts.
        </div>
        <div class="mb-3">
          ⚠️ Limited slots left for {{ getMonth(currentMonth) }}/{{ getMonth(nextMonth) }}! Free game migration - just send us your game in a Word or PDF document and our onboarding team will create the game on PlayTours for you.
        </div>
        <div class="buttons">
          <button
            @click="openTidycal()"
            class="button is-primary"
          >
          <i class="fas fa-phone mr-2"></i>
            Schedule a free call
          </button>
          <button
            @click="close()"
            class="button is-grey"
          >
          <i class="fas fa-times mr-2"></i>
            Close
          </button>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'

export default {
  name: 'ContactSolutionsTeam',
  components: {
    CardComponent
  },
  data () {
    return {
      hideContactSolutionsTeam: false,
      currentMonth: null,
      nextMonth: null
    }
  },
  methods: {
    openTidycal () {
      window.open('https://tidycal.com/mnyvvp3/playtours-free-solutions-meeting')
    },
    close () {
      localStorage.hideContactSolutionsTeam = 'true'
      this.hideContactSolutionsTeam = true
    },
    getMonth (timestamp) {
      const date = new Date(timestamp)
      return date.toLocaleString('default', { month: 'long' })
    }
  },
  mounted () {
    this.hideContactSolutionsTeam = !!localStorage.hideContactSolutionsTeam
    const currentDate = new Date()
    this.currentMonth = currentDate.getTime()
    this.nextMonth = currentDate.setMonth(currentDate.getMonth() + 1)
  }
}
</script>
