<template>
  <footer v-show="isFooterBarVisible" class="footer">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="footer-copyright is-size-7">
              <b>&copy; {{ year }}, PLAYTOURS PTE. LTD.</b>
              <!-- <span class="tag">v1.5.1</span> -->
            </div>
          </div>
        </div>
        <!-- <div class="level-right">
          <div class="level-item">
            <div class="logo">
              <a href="https://justboil.me">
                <img src="../assets/justboil-logo.svg" alt="JustBoil.me" />
              </a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'

export default {
  name: 'FooterBar',
  computed: {
    year () {
      return dayjs().year()
    },
    ...mapState(['isFooterBarVisible'])
  }
}
</script>
