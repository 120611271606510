<template>
  <card-component
    class="has-table has-mobile-sort-spaced"
    title="Get Started"
    icon="fa-forward"
  >
    <tiles>
      <article
      v-for="(item, index) in guides"
      :key="index"
      class="message is-white px-5 pb- pt-2"
    >
        <div class="message-header px-0">
          <p>{{ item.header }}</p>
        </div>
        <div class="message-body px-0 pt-1">
          {{ item.body }}
        </div>
        <div>
        <button
          @click="item.method"
          class="button is-primary"
        >
          {{ item.button }}
        </button>
        </div>
      </article>
    </tiles>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import Tiles from '@/components/Tiles'

export default {
  name: 'NewUserGuide',
  components: {
    CardComponent,
    Tiles
  },
  computed: {
    guides () {
      return [
        {
          header: 'Step 1: Learn to create your first game on PlayTours',
          body: 'The best way to learn is to actually play one. Go to the PlayTours page to check out your first automatically-generated game!',
          button: 'Play your first game',
          method: () => {
            this.$router.push({ name: 'playtours' })
          }
        },
        {
          header: 'Step 2: Set up your Organisation',
          body: 'Change your organisation\'s name and logo.',
          button: 'Set Up Organisation',
          method: () => {
            this.$router.push({ name: 'organisation' })
          }
        },
        {
          header: 'Step 3: Add your first admin',
          body: 'Admins can view, create and edit any PlayTours. They can also upload and view files used in any PlayTour.',
          button: 'Manage Admins',
          method: () => {
            this.$router.push({ name: 'admins' })
          }
        },
        {
          header: 'Step 4: Read our privacy policy',
          body: 'You and your players\' privacy is of utmost importance to us.',
          button: 'See our privacy policy',
          method: () => {
            this.$router.push({ name: 'privacyPolicy' })
          }
        },
        {
          header: 'Step 5: Increase max concurrent active devices limit',
          body: 'See our subscription page.',
          button: 'Go to Subscription Page',
          method: () => {
            this.$router.push({ name: 'subscription' })
          }
        },
        {
          header: 'Request for PlayTours data policies',
          body: 'If you would like a copy of PlayTours\'s data policies, please drop us an email at hello@playtours.app',
          button: 'Copy email to clipboard',
          method: () => {
            navigator.clipboard.writeText('hello@playtours.app').then(() => {
              alert('Email copied to clipboard. Please request the data policies via email.')
            })
          }
        }
      ]
    }
  }
}
</script>

<style>

</style>
