<template>
  <aside v-show="isAsideVisible" class="aside is-placed-left is-expanded">
    <aside-tools :is-main-menu="true">
      <span slot="label">
        <img
        class="navbar-logo mt-3"
        src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FPlayTours-logo-horizontal.png?alt=media&token=248d7f9d-c1ca-4edc-9836-a4d3f8672c3d"
        >
      </span>
    </aside-tools>
    <div class="menu is-menu-main pt-3">
      <template v-for="(menuGroup, index) in menu">
        <p v-if="typeof menuGroup === 'string'" :key="index" class="menu-label">
          {{ menuGroup }}
        </p>
        <aside-menu-list
          v-else
          :key="index"
          :menu="menuGroup"
          @menu-click="menuClick"
        />
      </template>
    </div>
    <footer-bar />
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import AsideTools from '@/components/AsideTools'
import AsideMenuList from '@/components/AsideMenuList'
import FooterBar from '@/components/FooterBar'

export default {
  name: 'AsideMenu',
  components: { AsideTools, AsideMenuList, FooterBar },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(['isAsideVisible'])
  },
  methods: {
    menuClick (item) {
      //
    }
  }
}
</script>

<style>
.navbar-logo {
  max-width: 140px
}
</style>
