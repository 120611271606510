<template>
  <router-link :to="{name:'playtoursAssistant'}" class="button is-primary">
    <i class="fas fa-plus mr-2"></i>
    {{ showContinueGame ? 'Continue creating game' : 'Create game' }}
  </router-link>
</template>

<script>
export default {
  name: 'CreateGameButton',
  data () {
    return {
      showContinueGame: false
    }
  },
  mounted () {
    this.showContinueGame = !!localStorage.getItem('monabrunJson')
  }
}
</script>
